<template>
  <div class="app">
    <Sidebar />
    <div class="app-container">
      <Topnav />
      <NuxtPage />
    </div>
  </div>
</template>

<script lang="ts">
</script>

<style scoped lang="scss">
.app {
  width: 100vw;
  height: 100vh;
  display: flex;

  .app-container {
    flex: 1 1 100%;
  }
}
</style>
