<template>
  <div class="menu-item" :class="{ active: menu.active }" v-if="menu.children">
    <span :class="{ open: menu.open }" @click="() => onToggle()">{{
      menu.label
    }}</span>
    <MenuList :menus="menu.children" v-if="menu.open" />
  </div>
  <div class="menu-item" :class="{ active: menu.active }" v-else>
    <NuxtLink :to="menu.link">{{ menu.label }}</NuxtLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ menu: IMenu }>();

function onToggle() {
  useEvent().emit(
    "onMenuToggle",
    props.menu.id,
    (props.menu.open = !props.menu.open)
  );
}
</script>

<style scoped lang="scss">
.menu-item {
  padding-right: 0;

  & > * {
    display: block;
    padding: 1em;
    padding-right: 0;
    margin-right: 1em;
    color: #333333;
  }

  &.active > a,
  &.active > span,
  & > span.open {
    font-weight: bold;
    color: #000;
  }

  & > a,
  & > span {
    &:hover {
      background: #eee;
    }
  }

  span {
    position: relative;
    cursor: pointer;

    &:after {
      font-family: "Font Awesome 6 Free";
      content: "\f107";
      font-weight: 900;
      position: absolute;
      right: 20px;
    }

    &.open:after {
      content: "\f106";
    }
  }
}
</style>
