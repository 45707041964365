<template>
  <div class="sidebar" :class="{ open: open }">
    <div class="brand">
      <NuxtLink to="/"><img src="/images/logo.png" /></NuxtLink>
    </div>
    <div class="menu-container">
      <MenuList :key="renderKey" :menus="menus" />
    </div>
    <button type="button" class="toggle" @click="() => onClickToggle()">
      <i class="fa-solid fa-angles-left" v-if="open"></i>
      <i class="fa-solid fa-angles-right" v-else></i>
    </button>
  </div>
</template>

<script lang="ts">
import { flatten } from "@/assets/commons";

interface IMenuMap {
  [key: string]: IMenu;
}

export default {
  async setup() {
    const menus: IMenu[] = useStorage().session.get("menus");
    const open = ref<boolean>(true);
    const renderKey = ref<number>(0);
    const menuMap = ref<IMenuMap>({});
    const flatMenus = flatten(menus);
    flatMenus.forEach((menu) => {
      menuMap.value[menu.id] = menu;
    });

    setMenuStructure(useRoute().path);
    useRouter().afterEach((to) => {
      setMenuStructure(to.path);
    });

    function setMenuStructure(path: string) {
      var item: IMenu | undefined = flatMenus.find((e) => e.link === path);
      if (!item) return;
      flatMenus.forEach((e) => {
        e.active = false;
        e.open = false;
      });
      while (item) {
        item.open = true;
        item.active = true;
        // @ts-ignore
        item = menuMap.value[item.parentId];
      }
      ++renderKey.value;
    }

    return { menus, menuMap, open, renderKey };
  },
  methods: {
    onClickToggle() {
      this.open = !this.open;
    },
    onMenuToggle(id: string, open: boolean) {
      this.menuMap[id].open = open;
      ++this.renderKey;
    },
  },
  mounted() {
    useEvent().on("onMenuToggle", this.onMenuToggle);
  },
  beforeUnmount() {
    useEvent().remove("onMenuToggle", this.onMenuToggle);
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  flex: 0 0 200px;
  margin-left: -200px;
  margin-right: 24px;
  border-right: 1px solid #eee;
  position: relative;
  transition: margin 0.5s ease-in-out;
  background: #fff;

  &.open {
    margin-left: 0;
    transition: margin 0.5s ease-in-out;
  }

  .brand {
    height: 120px;
    margin-bottom: 40px;

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 2em;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .toggle {
    position: absolute;
    right: -23px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 42px;
    border: 1px solid #eee;
    border-left: none;
    font-size: 10px;
    background-color: #fff;
    cursor: pointer;
  }

  .menu-container {
    height: calc(100vh - 160px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
