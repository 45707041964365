<template>
  <div class="top-nav">
    <div class="nav-items">
      <div class="nav-item">
        <button
          type="button"
          class="button is-white"
          @click="() => onClickLogout()"
        >
          로그아웃
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  setup() {},
  methods: {
    onClickLogout() {
      useSession().logout()
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.top-nav {
  height: 60px;
  margin-left: -24px;
  margin-bottom: 20px;
  padding-right: 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .nav-items {
    height: 100%;
    display: flex;

    .nav-item {
      height: 100%;
      .button {
        height: 100%;
      }
    }
  }
}
</style>
